
export enum WorkosLoginType 
    {   
    GOOGLE_OAUTH = 1
}

export enum WorkosPassHashType
    {
    SSHA = 'ssha',
    BCRYPT = 'bcrypt',
    FIREBASE = 'firebase-scrypt'
}

export enum WorkosUserOrgRoles
{
    USER = "member",
    ADMIN = "admin",
    SUPERADMIN = "superadmin"
}