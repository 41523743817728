import { configureStore } from '@reduxjs/toolkit';

import general from './general/General';
import organization from './organization/Organization';
import users from './settings/Users';
import EventsData from './events/EventsData';
import events from './events/Events';
import csrfTokenValue from './authToken';
import paginationData from './paginationData';
import billingDetails from './billing/billingInfo';

export const store = configureStore({
    reducer: {
        events: events,
        eventsData: EventsData,
        users: users,
        general: general,
        organization: organization,
        csrfTokenValue: csrfTokenValue,
        paginationData: paginationData,
        billingDetails: billingDetails
    }
});
