import $ from 'jquery';
import APP_CONSTANTS from './constants';
import eventBus from './event-bus';
import { logoutUser } from './main';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import toast from 'react-hot-toast';

/**
 * 
 * @param {object} obj 
 * @returns {string}
 */
export function convertJSONToGETParams(obj) 
{
    const keys = Object.keys(obj);
    const result = [];
    for (const key of keys) 
    {
        let value = obj[key];
        if (value === null || typeof value === 'undefined') 
        {
            continue;
        }
        if (typeof value === 'object') 
        {
            value = JSON.stringify(value);
        }
        result.push(`${key}=${value}`);
    }
    if (result.length > 0) 
    {
        return result.join('&');
    }
    return '';
}

export function buildInitialsForPicture(emailId?: string, firstName?: string, lastName?: string) 
{
    let initials = '';
    if (firstName) 
    {
        initials = `${firstName.charAt(0).toUpperCase()}${lastName ? lastName.charAt(0).toUpperCase() : ''}`;
    }
    else 
    {
        initials = emailId.charAt(0).toUpperCase();
    }
    return initials;
}

export const convertKeysToTitleCaseAndFlatten = (obj: Record<string, any>): Record<string, any> => 
{
    return _.transform(obj, (result: Record<string, any>, value: any, key: string) => {
        let titleCasedKey: string = _.startCase(key).replace(/\s+/g, '');

        if (_.isPlainObject(value)) 
        {
            _.assign(result, convertKeysToTitleCaseAndFlatten(value));
        } 
        else if (_.isArray(value)) 
        {
            result[titleCasedKey] = value.join(", ");
        }
        else 
        {
            result[titleCasedKey] = value;
        }
    }, {});
};

export const transformTitleToLink = (title: string): string =>
{
    return title
        .toLowerCase()
        .replace(/\s+/g, '-'); // Replace spaces with hyphens
};

// needed for future

// export const convertKeysToTitleCaseAndFlatten = (obj: Record<string, any>, parentKey: string = ''): Record<string, any> => 
// {
//     return _.transform(obj, (result: Record<string, any>, value: any, key: string) => 
//     {
//         let currentKey = _.startCase(key).replace(/\s+/g, '');
//         let newKey = parentKey ? `${parentKey} ${currentKey}` : currentKey;

//         if (_.isPlainObject(value)) 
//         {
//             _.assign(result, convertKeysToTitleCaseAndFlatten(value, newKey));
//         } 
//         else if (_.isArray(value)) 
//         {
//             result[newKey] = value.join(", ");
//         } 
//         else 
//         {
//             result[newKey] = value;
//         }
//     }, {});
// }


/**
 * 
 * @param html 
 * @returns {string}
 */
export const validateEmptyHtml = (html: string): string =>
{
    const tempHtml = document.createElement('div');
    tempHtml.innerHTML = html;
    return tempHtml.innerText.trim();
};

export const stripHtmlTags = (htmlString: string): string => {
    const temporaryElement = document.createElement("div");
    temporaryElement.innerHTML = htmlString;
    return temporaryElement.textContent || temporaryElement.innerText || "";
};

/**
 * 
 * @param {string} firstName 
 * @param {string} lastName 
 * @returns {string}
 */
export function parseName(firstName, lastName) 
{
    return firstName && lastName ? firstName + ' ' + lastName : firstName ? firstName : lastName;
}

export const convertKeysToTitleCase = (obj: object): any => 
{
    const newObj = {
    };
    for (const key in obj) 
    {
        if (obj?.hasOwnProperty(key)) 
        {
            newObj[(key.charAt(0).toUpperCase() + key.slice(1))] = obj[key];
        }
    }
    return newObj;
};

export const isImageBuffer = (buffer: any) => 
{
    if (buffer.length < 3) 
    {
        return false;
    }
    if (buffer[0] === 0xFF && buffer[1] === 0xD8) 
    {
        return true;
    }
    if (
        buffer[0] === 0x89 &&
        buffer[1] === 0x50 &&
        buffer[2] === 0x4E &&
        buffer[3] === 0x47
    ) 
    {
        return true;
    }
};

export const getSimilarKey = (obj: object, key: string) => 
{
    return Object.keys(obj).find(k => k.replace(/\W/g, '').toLowerCase() === key.replace(/\W/g, '').toLowerCase());
};

export const omitDuplicatesAndRemovePunctuations = (arr: string[]): string[] => {
    const uniqueMap: { [key: string]: string } = {};
    
    arr.forEach(item => {
        // Normalize by trimming and converting to lower case
        const normalized = item.trim().toLowerCase().replace(/[?!.]*$/, ''); // Remove trailing punctuation

        // Only add to uniqueMap if it hasn't been seen yet
        if (!uniqueMap[normalized]) {
            uniqueMap[normalized] = item; // Store the original item
        }
    });

    // Return the values from the uniqueMap
    return Object.values(uniqueMap);
};

/**
 * Combines a moment date with a time string and returns a Unix timestamp.
 * If the input date is not a valid moment object, it attempts to parse it as a string.
 * Optionally converts the timestamp to a specified timezone.
 * 
 * @param {Moment | string} date - The date as a Moment object or a string in 'DD/MM/YYYY' format.
 * @param {string} time - The time as a string in 'HH:mm' format.
 * @param {string} [timezone] - The optional timezone string (e.g., 'America/New_York').
 * @returns {number} - The combined Unix timestamp in milliseconds.
 */
export const combineDateTime = (date: Moment | string, time: string, timezone?: string): number => {
    let dateMoment: Moment;

    // Check if the incoming date is already a valid moment object, otherwise convert it
    if (moment.isMoment(date) && date.isValid()) {
        dateMoment = date;
    } else {
        // Try to parse the date string (assuming it's in 'DD/MM/YYYY' format)
        dateMoment = moment(date, 'DD/MM/YYYY', true);
    }

    // If the date is still invalid after conversion, return NaN or handle it as needed
    if (!dateMoment.isValid()) {
        console.error('Invalid date format provided');
        return NaN;  // Or return 0, depending on how you want to handle invalid cases
    }

    // Split the time string into hours and minutes
    const [hours, minutes] = time.split(':').map(Number);

    // Set the hours and minutes on the date
    let combinedDateTime = dateMoment.clone().set({
        hour: hours,
        minute: minutes,
        second: 0,  // Reset seconds to 0
        millisecond: 0  // Reset milliseconds to 0
    });

    // If a timezone is provided, convert the combined date-time to that timezone
    if (timezone) {
        combinedDateTime = combinedDateTime.tz(timezone, true);
    }

    // Convert to Unix timestamp (seconds)
    return Math.floor(combinedDateTime.valueOf() / 1000);
};

export const getNestedValueFromObject = (obj: Record<string, any>, path: string): any => 
{
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const getMatchingIds = (target: string[], dataset: any[], keyToFilter: string): number[] | string [] => 
{
    const matchingIds = dataset
        .filter(item => target.includes(item[keyToFilter]))
        .map(item => item.id);
    return matchingIds;
}

export const isValidUrl = (value: string): boolean => {
    const urlPattern = new RegExp(
        '^(https?:\\/\\/)?' + // protocol
        '((([a-zA-Z0-9$-_@.&+!*"(),]|[a-zA-Z0-9-]+\\.)+[a-zA-Z]{2,})|' + // domain name and extension
        '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
        '(\\:\\d+)?(\\/[-a-zA-Z0-9%_.~+]*)*' + // port and path
        '(\\?[;&a-zA-Z0-9%_.~+=-]*)?' + // query string
        '(\\#[-a-zA-Z0-9_]*)?$' // fragment locator
    );
    return !!urlPattern.test(value);
};

export const NumberFormatter = (value: string | number): string => 
{
    const formatNegativeToPositive = (value: number): number => 
    {
        return Math.abs(value);
    };

    const isNegative = (value: number): boolean => 
    {
        return value < 0; 
    };  

    const formattedValue = (value: number, isNegative?: boolean): string => 
    {
        let formatted: string;

        if (value >= 1000000000) 
        {
            formatted = (value / 1000000000).toFixed(1) + 'B';
        }
        else if (value >= 1000000) 
        {
            formatted = (value / 1000000).toFixed(1) + 'M';
        }
        else if (value >= 1000) 
        {
            formatted = (value / 1000).toFixed(1) + 'K';
        }
        else 
        {
            formatted = value.toString();
        }

        return isNegative ? ('-' + formatted) : formatted;
    };
    return isNaN(Number(value)) ? '' : isNegative(Number(value)) ? formattedValue(formatNegativeToPositive(Number(value)), true) : formattedValue(Number(value));
};

export const FormatNumberWithoutDecimals = (value): string => 
{
    const formattedValue = (value): string => 
    {
        let formatted: string;

        if (value >= 1000000000) 
        {
            formatted = (value / 1000000000) + 'B';
        }
        else if (value >= 1000000) 
        {
            formatted = (value / 1000000) + 'M';
        }
        else if (value >= 1000) 
        {
            formatted = (value / 1000) + 'K';
        }
        else 
        {
            formatted = value.toString();
        }

        return formatted;
    };

    return isNaN(value) ? '' : formattedValue(value);
};

export const getCsrfToken = async () => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/users/auth-token';
    return triggerApi(API_URL, 'GET', '');
};
// TRIGGERAPI
/**
 * 
 * @param {string} URL 
 * @param {'PUT' | 'GET' | 'POST' | 'DELETE'} METHOD 
 * @param {*} DATA 
 * @param {'application/json'} CONTENT_TYPE 
 * @returns {Promise}
 */
export const triggerApi = async (URL: string, METHOD: string, DATA: any, csrfToken?: string, CONTENT_TYPE?: string | boolean, SKIP_CHECK?: boolean, sponsorPortal?: boolean): Promise<any> => 
{
    if (import.meta.env.VITE_NODE_ENV === 'demo' && (METHOD === 'POST' && !(URL.includes('/authentication/login') || URL.includes('/authentication/logout')))) 
    {
        const shouldSkipCall = METHOD === 'POST' || METHOD === 'PUT' || METHOD === 'DELETE';
        if (shouldSkipCall) 
        {
            return new Error('demo data');
        }
    }
    const apiParams = {
        'url': URL,
        'method': METHOD,
        'xhrFields': {
            withCredentials: true
        },
        'crossDomain': true
    };
    if (!SKIP_CHECK && METHOD === 'POST' && csrfToken) 
    {
        apiParams['headers'] = {
            'X-CSRF-Token': csrfToken
        };
    }
    if (METHOD === 'POST' || METHOD === 'PUT' || (METHOD === 'DELETE' && DATA != '')) 
    {
        apiParams['contentType'] = 'application/x-www-form-urlencoded';
        apiParams['data'] = DATA;
    }
    if (CONTENT_TYPE) 
    {
        apiParams['contentType'] = CONTENT_TYPE;
    }
    if (typeof (CONTENT_TYPE) === 'boolean') 
    {
        apiParams['contentType'] = CONTENT_TYPE;
        apiParams['processData'] = CONTENT_TYPE;

    }
    return new Promise(function (resolve, reject) 
    {
        apiParams['success'] = function (data) 
        {
            resolve(data);
        };
        apiParams['error'] = function (data) 
        {
            eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_BUTTON, {
                spinner: false,
            });

            if (data.responseJSON) 
            {
                if (Number(data.responseJSON.statusCode) === 403) 
                {
                    // $.publish('error', data.responseJSON);
                    logoutUser('reload', sponsorPortal);
                    reject(data.responseJSON);
                }
                if (Number(data.responseJSON.statusCode) === 401) 
                {
                    toast.error('You are not authorized to do this action');
                }
                if (Number(data.responseJSON.statusCode) === 500) 
                {
                    toast.error((data.responseJSON.message !== 'ERROR IN QUERY' || data?.responseJSON?.message !== 'Internal server error') ? data.responseJSON.message : 'Something went wrong');
                }
                if (data.responseJSON.message) 
                {
                    if (data.responseJSON.message.includes('REQUIRES PLAN UPGRADATION')) 
                    {
                        // $.publish(APP_CONSTANTS.EVENTS.TRIGGER_POPUP, {
                        //     heading: 'Upgrade Plan',
                        //     body: 'The feature requires plan upgradation.<br><br> Contact support@eventhq.io to request access',
                        //     acceptText: 'OK',
                        //     cancelText: null,
                        //     handleAccept: async () => 
                        //     {
                        //         this.updateOrgDetails(this.orgLinkInput.value, this.orgNameInput.value);
                        //     },
                        //     handleCancel: async () => 
                        //     {
                        //     }
                        // });
                        // $.publish('email-sharing-error-inactive', data.responseJSON);
                    }
                    else if (data.responseJSON.message === 'UnAuthorized') 
                    {
                        // $.publish('email-sharing-error', data.responseJSON);
                    }
                }

                // if (data.responseJSON.statusCode === 200) {
                //     eventBus.dispatch('spinner-status', {
                //         spinner: false,
                //     });
                // }
            }

            reject(data.responseJSON);
        };
        $.ajax(apiParams);
    });
};