import React from 'react';
import 'react-pro-sidebar/dist/css/styles.css';
import './styles.scss';
import { Outlet } from 'react-router-dom';

const AppLayout = () => 
{
    return (
        <main className="App">
            <Outlet />
        </main>
    );
};

export default AppLayout;
