import React, {  } from "react";
// import { Meta, UppyFile } from "@uppy/core";
// import "@uppy/core/dist/style.css";
// import "@uppy/dashboard/dist/style.css";
// import "@uppy/drag-drop/dist/style.css";
import { useSelector } from "react-redux";
import _ from "lodash";

import './styles.scss';
import FilePondPhotos from "../../../pages/Events/EventDetails/PhotoSharing/FilePond";

interface AddPhotosProps { 
    eventId: string | number;
    folderId: string | number;
    isDefault?: boolean;
}

const AddPhotos: React.FC<AddPhotosProps> = (props) => {

    const { eventId, folderId, isDefault } = props;


    const csrfTokenData = useSelector((state): string => {
        return state['csrfTokenValue'].value.csrfToken;
    });

    // const uppy = React.useMemo(() => {
    //     return new Uppy({
    //         restrictions: { maxNumberOfFiles: 50 },
    //         autoProceed: false,
    //     });
    // }, []);

    // const [files, setFiles] = useState<UppyFile<Meta, Record<string, never>>[]>([]);
    // const [uploadProgress, setUploadProgress] = useState<{ [key: string]: number }>({});
    // const [cumulativeProgress, setCumulativeProgress] = useState(0);

    // useEffect(() => {
    //     uppy.on('files-added', (newFiles) => {
    //         setFiles(existingFiles => {
    //             const existingMap = new Map(existingFiles.map(file => [file.id, file]));
    //             newFiles.forEach(file => {
    //                 if (!existingMap.has(file.id)) {
    //                     existingMap.set(file.id, file);
    //                 }
    //             });
    //             return Array.from(existingMap.values());
    //         });
    //     });

    //     uppy.on('upload-success', () => {
    //         alert('hi');
    //     })

    //     uppy.on('file-removed', (removedFile) => {
    //         setFiles(existingFiles => {
    //             return existingFiles.filter(file => file.id !== removedFile.id);
    //         });
    //     });

    //     // return () => {
    //     //     uppy.close();
    //     // };
    // }, [uppy]);

    // const handleDialogClose = (): void =>
    // {
    //     eventBus.dispatch(APP_CONSTANTS.EVENTS.DIALOG.CLOSE_EVENT, {
    //         open: false,
    //     });
    // };

    // useEffect(() => {
    //     const totalFiles = files.length;
    //     const totalProgress = Object.values(uploadProgress).reduce((acc, curr) => acc + curr, 0);
    //     const averageProgress = totalFiles > 0 ? totalProgress / totalFiles : 0;
    //     setCumulativeProgress(averageProgress);
    //     if(cumulativeProgress === 100) 
    //     {
    //         setTimeout(() => {
    //             handleDialogClose();
    //         }, 1000);
    //     }
    // }, [uploadProgress, files]);

    // const handleUpload = async () => {
    //     try {
    //         const uploadPromises = files.map((file) => {
    //             const formData = new FormData();
    //             formData.append('file', file.data);

    //             return axios.post(`${APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1}/event-photo-sharing/${eventId}/${folderId}/upload-images${isDefault ? '?type=1' : ''}`, 
    //                 formData, 
    //                 {
    //                     headers: {
    //                         'Content-Type': 'multipart/form-data',
    //                         'X-CSRF-Token': csrfTokenData,
    //                     },
    //                     withCredentials: true,
    //                     onUploadProgress: (progressEvent) => {
    //                         const progress = (progressEvent.loaded * 100) / progressEvent.total;
    //                         setUploadProgress((prevProgress) => ({
    //                             ...prevProgress,
    //                             [file.id]: progress,
    //                         }));
    //                     },
    //                 }
    //             ).then((response) => {
    //                 uppy.setFileState(file.id, { response: response.data });
    //             }).catch((error) => {
    //                 console.error('Error in uploading file', error);
    //             });

    //         });

    //         await Promise.all(uploadPromises);
    //         // Ensure progress is set to 100% after all uploads complete
    //         setUploadProgress((prevProgress) => {
    //             const updatedProgress = { ...prevProgress };
    //             files.forEach(file => {
    //                 updatedProgress[file.id] = 100;
    //             });
    //             return updatedProgress;
    //         });
    //     } catch (error) {
    //         console.error('Error in uploading files', error);
    //     }
    // };

    return (
        <div id="addPhotos">
            <FilePondPhotos eventId={eventId} folderId={folderId} isDefault={isDefault} csrfToken={csrfTokenData} />
            {/* <Dashboard uppy={uppy} plugins={["DragDrop"]} hideUploadButton />
            {files.length > 0 && (
                <div className='photos-button-container'>
                    {cumulativeProgress === 0 ? (
                        <CustomButton 
                            onClick={handleUpload} 
                            style={{ width: '100%' }} 
                            btnType="primary" 
                            name={`Upload ${files.length} ${files.length === 1 ? 'File' : 'Files'}`} 
                        />
                    ) : (
                        <ProgressBar 
                            now={cumulativeProgress} 
                            max={100} 
                            label={`${Math.round(cumulativeProgress)}%`} 
                        />
                    )}
                </div>
            )} */}
        </div>
    );
};

export default AddPhotos;
