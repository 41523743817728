import React, { Suspense, useEffect, useState } from 'react';
import { Routes, Route, Navigate, useSearchParams } from 'react-router-dom';
import { CONTENT } from '../scripts/i18n';
import SettingsPage from '../pages/Settings/SettingsPage';
import AppLayout from '../layouts/AppLayout';
import ProtectedRoute from '../layouts/ProtectedRoute';
import AuthenticatorRoute from '../layouts/AuthenticatorRoute';
import EventsInfo from '../pages/Events/EventsInfo';
import EventsPage from '../pages/Events/EventsPage';
import { AnimatePresence } from 'framer-motion';
import UserLoginPage from '../pages/Authentication/UserLoginPage';
import UserFlowPage from '../pages/Authentication/UserFlowPage';
import EventsPageProvider from '../contexts/EventsPageContext';
import { orgBillingDetails } from '../scripts/apis/billing';
import { useDispatch } from 'react-redux';
import { billingDetails } from '../redux/billing/billingInfo';
import { LocalStorage } from '../scripts/LocalStorage';
import { useSelector } from 'react-redux';
import { IBillingInfo } from '../redux/billing/billing-redux_interface';
import UserProvider from '../contexts/UserContext';

const AppRouter = (): React.JSX.Element => 
{

    const [searchParams] = useSearchParams();
    
    const dispatch = useDispatch();

    const [loading, isLoading] = useState<boolean>(true);
    const orgBillingInfo = useSelector((state: IBillingInfo) => state.billingDetails?.value?.billingDetails);

    const fetchOrgBillingDetails = async (): Promise<void> =>
    {
        try 
        {
            const user = LocalStorage.get('@UserMe');
            if (user)
            {
                const billingInfo = await orgBillingDetails();
                if (billingInfo)
                {
                    dispatch(billingDetails({
                        billingDetails: billingInfo
                    }));
                }
                else
                {
                    dispatch(billingDetails({
                        billingDetails: {
                            users: true,
                            billingModules: {
                                sponsor: true,
                                vendor: true,
                                photoSharing: {
                                    userUploadPhotoSharing: true,
                                    photoSharingWatermark: true
                                },
                                checkInApp: true,
                                connectApp: true,
                                integrations: true,
                            }
                        }
                    }));
                }
            }
        } 
        catch (error) 
        {
            console.log('Error fetching billing details', error);
            dispatch(billingDetails({
                billingDetails: {
                    users: true,
                    billingModules: {
                        sponsor: true,
                        vendor: true,
                        photoSharing: {
                            userUploadPhotoSharing: true,
                            photoSharingWatermark: true
                        },
                        checkInApp: true,
                        connectApp: true,
                        integrations: true,
                    }
                }
            }));
        }
        finally
        {
            isLoading(false);
        }
    };

    useEffect(() => {
        fetchOrgBillingDetails();
    }, []);

    return (
        <Suspense fallback={<div />}>
            <AnimatePresence exitBeforeEnter>
                {
                    loading ? <></> : 
                    <Routes>
                        <Route path="/" element={<AppLayout />}>

                            {/* initial path */}
                            <Route element={<ProtectedRoute />}>
                                <Route path={'/'} element={<Navigate to={CONTENT.PATHS.ROOT.TO} replace />} />
                            </Route>

                            {/* signup */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/signup" element={<UserLoginPage />} />
                            </Route>

                            {/* login */}
                            <Route element={<AuthenticatorRoute />}>
                                <Route path="/login" element={<UserLoginPage token={searchParams.get('token')} />} />
                            </Route>

                            {/* register as new user */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/register" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token')} component='register' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* forgot password */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/forgot-password" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token')} component='forgot-password' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* onboard / accept invite */}
                            <Route element={<AuthenticatorRoute preventLogout={true} />}>
                                <Route path="/onboard" element={searchParams.get('token') ? <UserFlowPage token={searchParams.get('token')} component='onboard' /> : <Navigate to="/login" replace />} />
                            </Route>

                            {/* events */}
                            <Route element={<UserProvider><ProtectedRoute side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:status?/:eventId?'} element={<EventsPageProvider><EventsPage/> </EventsPageProvider>} />
                            </Route>

                            {/* event photo sharing */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/events/:eventId/photo-sharing/:folder?/:hidden?/:default-photos?/:user-uploads?/:approved?'} element={<EventsInfo data={'photoSharing'} />} />
                            </Route>

                            {/* Settings Routes */}
                            {/* settings page -> redirects to profile settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings'} element={<Navigate to={'/settings/general'} replace />} />
                            </Route>

                            {/* profile settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/general'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.GENERAL.TAB} />} />
                            </Route>

                            {/* organisation settings */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/organization'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.ORGANIZATION.TAB} />} />
                            </Route>
                            
                            {/* users page */}
                            <Route element={<UserProvider><ProtectedRoute collapsed-side-bar={true} top-app-bar={false} /></UserProvider>}>
                                <Route path={'/settings/users'} element={<SettingsPage tab={CONTENT.PATHS.SETTINGS.USERS.TAB} />} />
                            </Route>

                        </Route>

                    </Routes>
                }
            </AnimatePresence>
        </Suspense>
    );
};
export default AppRouter;