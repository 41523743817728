import { Container } from '@mui/material';
import FormTabs from '../FormTabs';
import './styles.scss';

interface IMinimalViewComponentProps { 
    title: string | React.ReactNode;
    description: string | React.ReactNode;
    titleBarActions?: React.ReactNode[];
    tabData?: { tabName: string, count: string | number, navigation: () => void, selectedTab: boolean }[];
    actions?: React.ReactNode[];
    showCharts?: boolean;
    component?: React.ReactNode;
    componentType?: 'table' | 'list';
    headerActions?: React.ReactNode;
}

export const MinimalViewComponent: React.FC<IMinimalViewComponentProps> = (props): React.JSX.Element => { 

    const { title, description, titleBarActions, tabData, actions, showCharts, componentType = 'table', headerActions } = props;

    return (
        <div id="minimalViewComponent">
            <Container style={{ height: '100%' }}>
                <div className="minimal-view-component-container">

                    {/* title bar */}
                    <div style={{ 
                        paddingBottom: componentType === 'table' ? '24px' : '',
                        borderBottom: componentType === 'table' ? '1px solid #F2F4F7' : ''
                    }} className="title-bar-container">
                        <div className="header-actions">
                            {headerActions}
                        </div>
                        <div className="title-bar">
                            {/* title and description */}
                            <div className="title-description">
                                {typeof(title) === 'string' ? <h1 className="title">{title}</h1> : title}
                                {typeof(description) === 'string' ? <p className="description">{description}</p> : description}
                            </div>
                            {/* title bar actions */}
                            <div className="title-bar-actions">
                                {titleBarActions}
                            </div>
                        </div>
                    </div>

                    {/* tabs, search, filter and other actions */}
                    <div style={{ 
                        top: (
                            componentType === 'list' ? 
                                showCharts ? '204px' : '90px' : 
                                showCharts ? '229px' : '90px'
                        ),
                        borderBottom: componentType === 'list' ?  '1px solid var(--Colors-Border-border-tertiary, #F2F4F7)' : ''
                    }} className="minimal-view-actions">
                        {/* tabs */}
                        <FormTabs 
                            tabs={tabData || []}
                        />

                        {/* action buttons */}
                        <div className="action-buttons">
                            {/* action buttons */}
                            {actions}
                        </div>
                    </div>

                    {/* component */}
                    <div style={{ 
                        // height: !showCharts ? '100%' : ''
                    }} className="component">
                        {props.component}
                    </div>
                </div>
            </Container>
        </div>
    );
};