import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box } from '@mui/material';
import eventBus from '../../scripts/event-bus';
import { CONTENT } from '../../scripts/i18n';
import APP_CONSTANTS from '../../scripts/constants';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CreateEvent from '../../components/Events/EventComponents/CreateEvent';
import { resetEvents } from '../../redux/events/Events';
import { useDispatch } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { EventsPageContext } from '../../contexts/EventsPageContext';
import { getEventById } from '../../scripts/apis/events';
import TableEmptyComponent from '../../common/TableEmptyComponent';
import { Event } from './interfaces';
import { NumberFormatter } from '../../scripts/helpers';
import MuiChip from '../../common/FormComponents/MuiChip';
import eventsEmptyImage from '../../assets/icons/empty-state/eventEmpty.svg';
import { EventLocationType, EventPhase, EventStatus } from './enum';
import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import TanstackTable from '../../common/TanstackTable/TanstackTable';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { TableActions } from '../../common/TableActions';
import DeletePopup from '../../common/DeletePopup';
import HeaderBar from '../../common/Headerbar';
import TanstackToolbarWithServerFilter from '../../common/TanstackTable/TanstackToolbarWithServerFilter';

import './styles.scss';
import toast from 'react-hot-toast';
import { MinimalViewComponent } from '../../common/MinimalView/MinimalViewComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UserContext } from '../../contexts/UserContext';
import { UserViewType } from '../Settings/enum/users.enum';
import CustomSpinner from '../../common/CustomSpinner';

const EventsPage: React.FC = (): React.JSX.Element => 
{
    const dispatch = useDispatch();
    const searchParams = new URLSearchParams();
    const currentpath = useLocation().pathname;
    const { eventId } = useParams();

    const { 
        isEmpty, 
        setRows, 
        rows, 
        eventDataCount, 
        events, 
        // setEvents,
        deleteEventFromEventsTable, 
        setRefresh, 
        pageSize, 
        updateCurrentPage, 
        updatePageSize, 
        currentPage, 
        statusCounts,
        showSpinner, 
        showDeletePopup,
        setShowDeletePopup,
        setCurrentUrl, 
    } = useContext(EventsPageContext);

    const {
        userDetails
    } = useContext(UserContext);

    const [allColumns, setAllColumns] = useState<ColumnDef<Event, any>[]>([]);
    const [selectedEvent, setSelectedEvent] = useState<Event | null>(null);
    const minimalView = userDetails?.viewType === UserViewType.MINIMAL;

    const columnHelper = createColumnHelper<Event>();
    const eventColumns = useMemo(() => [
        columnHelper.accessor('title', { 
            cell: (row) => {
                return (<p className="bolded-cellContent">{row.getValue()}</p>)
            },
            header: 'Event Name',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'title'
        }),
        columnHelper.accessor('eventDates', {
            cell: (row) => {
                return (<p className="cellContent">{`${moment.unix(row.row.original.eventStartDateTime).format('MMM DD')} - ${moment.unix(row.row.original.eventEndDateTime).format('MMM DD')}`}</p>)
            },
            header: 'Event Dates',
            sortingFn: (rowA, rowB) => {
                const dateA = rowA.original.eventStartDateTime;
                const dateB = rowB.original.eventStartDateTime;
                return dateA - dateB;
            },
            id: 'eventDates'
        }),
        columnHelper.accessor('eventPhase', {
            cell: (row) => {
                const currentTimestamp = Math.floor(new Date().getTime()/1000.0);
                const isOngoingEvent = (currentTimestamp > row.row?.original?.eventStartDateTime && currentTimestamp < row.row?.original?.eventEndDateTime);
                const isUpcomingEvent = row.row?.original?.eventStartDateTime > currentTimestamp;
                const isEventEnded = currentTimestamp > row.row?.original?.eventEndDateTime;
                return (
                    <MuiChip 
                        label={isOngoingEvent ? 'Ongoing' : isUpcomingEvent ? 'Upcoming' : isEventEnded ? 'Ended' : '-'} 
                        chipColor={isOngoingEvent ? 'yellow' : isUpcomingEvent ? 'green' : isEventEnded ? 'grey' : 'null'}
                    />
                )
            },
            header: 'Phase',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'eventPhase'
        }),
        columnHelper.accessor('status', { 
            cell: (row) => {
                return (<MuiChip label={row.row?.original?.status === EventStatus.DRAFT ? 'Draft' : row.row?.original?.status === EventStatus.PUBLISHED && 'Published'} chipColor={row.row?.original?.status === EventStatus.DRAFT ? 'pink' : row.row?.original?.status === EventStatus.PUBLISHED && 'blue'} />)
            },
            header: 'Status',
            sortingFn: 'alphanumericCaseSensitive',
            id: 'status'
        }),
        columnHelper.accessor('actions' as any, {
            cell: (row) => {
                return (
                    <TableActions 
                        actions={[
                        {
                            title: 'Edit',
                            onClick: (): void => 
                            {
                                handleEdit(row.row.original);
                            }
                        }, 
                        {
                            title: 'Delete',
                            onClick: (): void => 
                            {
                                setSelectedEvent(row.row.original);
                                setShowDeletePopup(true);
                            }
                        }]}
                    />
                );
            },
            header: '',
            id: 'actions',
            size: 40,
            enableSorting: false,
        })
    ], []);

    // const [searchText, setSearchText] = useState<string>('');

    const navigate = useNavigate();

    const filterColumns = [
        {
            header: 'Event Name',
            id: 'eventName',
            meta: {
                type: 'string'
            }
        },
        {
            header: 'Event Start Date',
            id: 'eventStartDateTime',
            meta: {
                type: 'date'
            }
        },
        {
            header: 'Event End Date',
            id: 'eventEndDateTime',
            meta: {
                type: 'date'
            }
        },
        {
            header: 'Event Phase',
            id: 'eventPhase',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { name: 'Ongoing', value: EventPhase.ONGOING },
                    { name: 'Upcoming', value: EventPhase.UPCOMING },
                    { name: 'Ended', value: EventPhase.ENDED }
                ]
            }
        },
        {
            header: 'Status',
            id: 'status',
            meta: {
                type: 'dropdown',
                filterOptions: [
                    { name: 'Draft', value: EventStatus.DRAFT },
                    { name: 'Published', value: EventStatus.PUBLISHED }
                ]
            }
        }
    ];

    const eventsPageToolbarTabs = [
        {
            tabName: 'All',
            count: eventDataCount,
            navigation: () => {
                setCurrentUrl('/events');
                navigate('/events')
            },
            selectedTab: !window.location.search?.includes('status='),
        },
    ];

    const handleApplyFilters = (filters: { selectedColumn: string, inputValue: string }[]): void => {
        filters.forEach(filter => {
            searchParams.append(filter.selectedColumn, filter.inputValue);
        });
        setCurrentUrl(`${currentpath}?${searchParams.toString()}`);
        navigate(`${currentpath}?${searchParams.toString()}`);

        // code to append new query along with existing query
        // const currentSearchParams = new URLSearchParams(currentUrl.split('?')[1]);
        // filters.forEach(filter => {
        //     currentSearchParams.append(filter.selectedColumn, filter.inputValue);
        // });
        // setCurrentUrl(`${currentpath}?${currentSearchParams.toString()}`);
        // navigate(`${currentpath}?${currentSearchParams.toString()}`);
    };

    const handleEdit = (eventInfo: Event, routeFromId?: boolean): void => 
    {
        dispatch(resetEvents());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.EDIT.HEADING,
            component: <CreateEvent eventData={eventInfo} setRefresh={setRefresh} routeFromId={routeFromId} />,
            hideCloseButton: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const openDrawer = (): void => 
    {
        dispatch(resetEvents());
        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.UPDATE_EVENT, {
            heading: CONTENT.EVENTS_PAGE.SIDE_DRAWER_CONTENT.CREATE.HEADING,
            component: <CreateEvent setRefresh={setRefresh} />,
            hideCloseButton: true
        });

        eventBus.dispatch(APP_CONSTANTS.EVENTS.SIDE_DRAWER.OPEN_EVENT, {
            open: true,
            width: '500px !important'
        });
    };

    const fetchDataFromRoute = async (): Promise<void> => 
    {
        if (events && events.length && eventId) 
        {
            try 
            {
                const eventDataFromId = await getEventById(eventId);
                if (eventDataFromId) 
                {
                    handleEdit(eventDataFromId, true);
                }
                else if (!eventDataFromId) 
                {
                    toast.error('The event you\'re trying to access doesn\'t exist');
                    navigate('/events');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
        }
    };

    useEffect((): void => 
    {
        fetchDataFromRoute();
    }, [events]);

    useEffect(() => {
        setAllColumns(eventColumns);
    }, [eventColumns]);

    const minimalViewTabs = [
        {
            tabName: 'Upcoming',
            count: statusCounts && Number(statusCounts['UPCOMING']) || '-',
            navigation: () => {
                setCurrentUrl(`/events?eventPhase=${EventPhase.UPCOMING}`);
                navigate(`/events?eventPhase=${EventPhase.UPCOMING}`)
            },
            selectedTab: window.location.search?.includes(`eventPhase=${EventPhase.UPCOMING}`),
        },
        {
            tabName: 'Past',
            count: statusCounts && Number(statusCounts['PAST']) || '-',
            navigation: () => {
                setCurrentUrl(`/events?eventPhase=${EventPhase.ENDED}`)
                navigate(`/events?eventPhase=${EventPhase.ENDED}`)
            },
            selectedTab: window.location.search?.includes(`eventPhase=${EventPhase.ENDED}`),
        }
    ];

    // Function to group events by month
    const groupEventsByMonth = (events: Event[]): { [key: string]: Event[] } => {
        return events.reduce((acc: { [key: string]: Event[] }, event: Event) => {
            const month = moment.unix(event.eventStartDateTime).format('MMM');
            if (!acc[month]) {
                acc[month] = [];
            }
            acc[month].push(event);
            return acc;
        }, {});
    };

    const renderEventsAsCards = (): React.ReactNode => {
        
        const data = groupEventsByMonth(rows);

        return (
            <div className="events-card-component">
                <div className="events-card-container">
                    {
                        Object.keys(data)?.length > 0 && Object.keys(data).map((month) => (
                            <div key={month} className="events-section">
                                <h2 className="month">{month}</h2>
                                <div className="card-wrapper">
                                    {
                                        data[month].map((event: Event) => (
                                            <div key={event.id} onClick={() => navigate(`/events/${event.id}/photo-sharing`)} className="event-card">
                                                <div className="event-details">
                                                    <div className="event-timing-details">
                                                        <h3 className="date">{moment.unix(Number(event?.eventStartDateTime))?.format('DD')}</h3>
                                                        <h3 className="day">{moment.unix(Number(event?.eventStartDateTime))?.format('ddd')}</h3>
                                                    </div>
                                                    <div className="event-info-container">
                                                        <h3 className="event-title">{event?.title}</h3>
                                                        {event?.locationDetails?.name && event?.locationDetails?.name !== '' && 
                                                            <div className="event-location-details">
                                                                <FontAwesomeIcon icon={['fal', 'location-dot']} />
                                                                <h3 className="event-locationName">{event?.locationDetails?.name}</h3>
                                                            </div>
                                                        }
                                                        {
                                                            <div className="event-location-type">
                                                                <FontAwesomeIcon icon={['fal', event?.locationType === EventLocationType.ONSITE ? 'user-group' : event?.locationType === EventLocationType.VIRTUAL ? 'video' : 'users']} />
                                                                <h3 className="event-locationType-label">{CONTENT.EVENTS_PAGE.EVENT_LOCATION_CHIP_LABEL[event?.locationType]}</h3>
                                                            </div>
                                                        }
                                                    </div>
                                                    <div className="event-banner-container">
                                                        <img src={event?.eventBanner} alt='event-banner' className="event-banner-img" />
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        ))
                    }

                </div>
            </div>
        )
    };

    const renderMinimalViewForEvents = (): React.ReactNode => { 
        return (
            <div id="eventsMinimalView">
                <MinimalViewComponent 
                    title="Your Events"
                    description="Manage your events and their account permissions here."
                    titleBarActions={[
                        
                    ]}
                    tabData={minimalViewTabs}
                    actions={[
                        <CustomButton style={{ height: '38px', maxHeight: '38px', width: '38px' }} key={2} name={<FontAwesomeIcon icon={['fal', 'plus']} />} btnType='primary' onClick={() => openDrawer()} />
                    ]}
                    showCharts={false}
                    component={renderEventsAsCards()}
                    componentType='list'
                />
            </div>
        )
    };

    const renderTableViewForEvents = (): React.ReactNode => { 
        return (
            <Box id="eventsPage">
                <HeaderBar 
                    title={`Hello ${userDetails?.firstName ?? ''}`}
                    buttons={[
                        <CustomButton name='Create Event' btnType='primary' onClick={() => openDrawer()} />
                    ]}
                />
    
                <Box key={'event-page-table'}  
                    sx={{
                        flex: 1,
                        height: '56% !important',
                        maxHeight: 'calc(100vh - 60px)',
                    }}
                >
                    {
                        (isEmpty && Number(eventDataCount) == 0) ? (<TableEmptyComponent emptyImg={eventsEmptyImage} openDrawer={openDrawer} infoText={'No Event'} subInfoText={'Create Your First Event'} buttonName={'Create Event'} />) :
                            (
                            <div className="h-100">
                                <TanstackToolbarWithServerFilter 
                                    columns={eventColumns}
                                    filterColumns={filterColumns}
                                    setColumns={setAllColumns}
                                    tabs={eventsPageToolbarTabs}
                                    handleApplyFilters={handleApplyFilters}
                                    setCurrentUrl={setCurrentUrl}
                                />
                                <TanstackTable 
                                    data={rows}
                                    initialColumns={allColumns}
                                    pageSize={pageSize}
                                    updatePageSize={updatePageSize}
                                    currentPage={currentPage - 1}
                                    updateCurrentPage={updateCurrentPage as any}
                                    rowCount={eventDataCount}
                                    showNoRowsOverlay={false}
                                    rightPinnedColumns={['actions']}
                                    height={`calc(100% - 124px)`}
                                    onRowClick={(row) => navigate(`/events/${row.id}/photo-sharing`)}
                                    showSpinner={showSpinner}

                                />
                            </div>
                            )
                    }
                </Box>

                {
                    showDeletePopup && (
                        <DeletePopup
                            show={showDeletePopup} 
                            modalTitle='Delete Event ?'
                            modalContent={`Are you sure you want to delete the event - ${selectedEvent?.title} ?`}
                            acceptBtn='Delete'
                            rejectBtn='Cancel'
                            acceptClick={() => deleteEventFromEventsTable(selectedEvent?.id as string)}
                            cancelClick={() => setShowDeletePopup(false)}
                        />
                    )
                }
            </Box>
        )
    };

    return (
        <>
            {
                showSpinner ? 
                <CustomSpinner height='100%' /> : 
                userDetails && minimalView ? (
                    renderMinimalViewForEvents()
                ) : (userDetails && !minimalView) && (
                    renderTableViewForEvents()
                )
            }
        </>
    );
};

// Needed for future - *****IMPORTANT***** -

// const debouncedSearch = debounce(async (filters, setRows, searchEvents, getEvents, pageSize, currentPage) => 
// {
//     if (filters.length > 0 && filters.some((filter: { inputValue: string; }) => filter.inputValue !== '')) {
//         try {
//             const searchValues = filters.map((filter: { inputValue: any; }) => filter.inputValue);
//             const filteredSpeakers = await searchEvents(searchValues);
//             if (filteredSpeakers) {
//                 setRows(filteredSpeakers);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     } else {
//         try {
//             const speakersData = await getEvents(pageSize, currentPage - 1);
//             if (speakersData) {
//                 setRows(speakersData);
//             }
//         } catch (error) {
//             console.log(error);
//         }
//     }
// }, 300);

export default EventsPage;