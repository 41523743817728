import { createSlice } from '@reduxjs/toolkit';
import { UserModeration } from '../../pages/Events/enum/event.enum';

export const eventsRowSlick = createSlice({
    name: 'events',
    initialState: {
        value: {
            title: '',
            eventStartDate: '',
            eventStartTime: '',
            eventStartDateTime: '',
            eventEndDateTime: '',
            eventEndDate: '',
            eventEndTime: '',
            timeZone: '',
            userModeration: UserModeration.DISABLED,
            link: '',
            banner: '',
            status: '',
        }
    },
    reducers: {
        addEvent: (state, actions):void => 
        {
            state.value = {
                ...state.value,
                ...actions.payload,
            };
        },
        resetEvents: (state):void => 
        {
            state.value = {
                title: '',
                eventStartDate: '',
                eventStartTime: '',
                eventEndDate: '',
                eventEndTime: '',
                eventStartDateTime: '',
                eventEndDateTime: '',
                timeZone: '',
                userModeration: UserModeration.DISABLED,
                link: '',
                banner: '',
                status: ''
            };
        }
    },
});

export const { addEvent, resetEvents } = eventsRowSlick.actions;
export default eventsRowSlick.reducer;