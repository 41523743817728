import { orgMe } from './apis/organisation';
import { userMe } from './apis/users';
import { LocalStorage } from './LocalStorage';

export const userOnline = (userMe) => {
    userMe.then(async (data) => {
        LocalStorage.set('@UserMe', data);
        try {
            const org = await orgMe();
            if (org) {
                LocalStorage.set('@Org', {
                    name: org.name,
                    link: org.link,
                    additionalInfo: org.additionalInfo,
                    id: org.id
                });
            }

        } catch (error) {
            console.log(error);
            if (error.statusCode === 403) {
                LocalStorage.remove('@UserMe');
            }
        }

    }
        // () => {
        //     LocalStorage.remove('@UserMe');
        //     // $.publish('signout');
        // }
    );
};

export async function getUserOnline() {
    let userOnline;
    if (localStorage.getItem('@UserMe')) {
        userOnline = JSON.parse(localStorage.getItem('@UserMe'));
    }
    if (!userOnline) {
        userOnline = await userMe();
        if (!userOnline) {
            // $.publish('signout');
        }
        else {
            return userOnline;
        }
    }
    else {
        return userOnline;
    }
}

export async function logoutUser(action: string) {

    localStorage.clear();
    if (action === 'reload') {
        window.location.reload();
    }
}