import { Box, IconButton, Typography, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { getUserOrgInfoByToken, googleAuthenticateWithWorkOS, integrateWorkOS, login, loginWithWorkOS, registerUserWithWorkOS, resetPasswordWithWorkOS } from '../../scripts/apis/authentication';
import { userMe } from '../../scripts/apis/users';
import * as formik from 'formik';
import * as Yup from 'yup';
import './styles.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IUserLoginComponentConditions } from './UserLoginPageInterface';

import eventhqFavicon from '../../../public/loginLogo.svg';
import eventHqLogo from '../../assets/favicon.png';
import googleLogo from '../../assets/google-logo.svg';

import { FormControlComponent, FormLabelComponent } from '../../common/FormComponents/ReusableFormComponents';
import { CustomButton } from '../../common/FormComponents/Buttons';
import { WorkosLoginType } from '../../enums/auth/workos.enum';
import toast from 'react-hot-toast';
import APP_CONSTANTS from '../../scripts/constants';

const UserLoginPage: React.FC<{ token?: string }> = ({ token }) : React.JSX.Element => 
{

    const currentpath = useLocation().pathname;

    const [spinner, setSpinner] = useState(false);
    const [workosSpinner, setWorkosSpinner] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [componentConditions, setComponentConditions] = useState<IUserLoginComponentConditions>({
        invalidCredentials: false,
        isResetPasswordComponent: false,
        allLoginOptions: false,
        isLoginComponent: false,
        isSignUpComponent: false,
        isSignUpHelpTextComponent: false,
        isResetHelpTextComponent: false,
        showPassword: false,
        tooManyAttemptsErr: false,
        tooManyResetAttempts: false,
    });

    const setSingleCondition = (key: keyof IUserLoginComponentConditions) => {
        setComponentConditions(prevState => {
            const newState = Object.keys(prevState).reduce((acc, currKey) => {
                acc[currKey as keyof IUserLoginComponentConditions] = currKey === key;
                return acc;
            }, {} as IUserLoginComponentConditions);
            return newState;
        });
    };

    const navigate = useNavigate();

    // useEffect(():void => 
    // {
    //     const captureFingerprint = async (): Promise<void> => 
    //     {
    //         try 
    //         {
    //             const components = await Fingerprint2.getPromise({
    //             });
    //             const fingerprint = Fingerprint2.x64hash128(components.map((pair): string => 
    //             {
    //                 return pair.value; 
    //             }).join(''), 31);
    //         }
    //         catch (error) 
    //         {
    //             console.error('Error capturing fingerprint:', error);
    //         }
    //     };
    //     captureFingerprint();
    // }, []);

    useEffect(():void => 
    {
        if (import.meta.env.VITE_NODE_ENV === 'demo') 
        {
            userLogin({
                email: import.meta.env.VITE_DEMO_EMAIL,
                password: import.meta.env.VITE_DEMO_PASSWORD
            });
        }

        if(token)
        {
            getTokenInfo();

            handleWorkOSLogin();
        }

        if(currentpath.includes('login'))
        {
            setSingleCondition('isLoginComponent');
        }
        else if(currentpath.includes('signup'))
        {
            setSingleCondition('isSignUpComponent');
        }
    }, []);

    const userLogin = async (values): Promise<void> => 
    {
        const loginObj = {
            email: values.email,
            password: values.password,
        };

        if(import.meta.env.VITE_NODE_ENV === 'demo')
        {
            try 
            {
                const data = await login(loginObj);
                if (data) 
                {
                    const user = await userMe();
                    if (user) 
                    {
                        // navigate('/events');
                        window.location.href = `${APP_CONSTANTS.APP_DOMAIN}/events`;
                        setSpinner(false);
                    }
                }
            }
            catch (error) 
            {
                console.log(error);
                setSpinner(false);
                if (error.error === 'Not Acceptable') 
                {
                    setComponentConditions((prevItems): IUserLoginComponentConditions => 
                    {
                        return {
                            ...prevItems,
                            invalidCredentials: false,
                            tooManyAttemptsErr: true,
                        }; 
                    });
                }
                else 
                {
                    setComponentConditions((prevItems): IUserLoginComponentConditions => 
                    {
                        return {
                            ...prevItems,
                            invalidCredentials: true,
                        }; 
                    });
                }
            }
            finally
            {
                setSpinner(false);
            }
        }
        else
        {
            try 
            {
                const loggedIn = await loginWithWorkOS(loginObj);
                if (loggedIn) 
                {
                    const user = await userMe();
                    if (user) 
                    {
                        // navigate('/events');
                        window.location.href = `${APP_CONSTANTS.APP_DOMAIN}/events`;
                        setSpinner(false);
                    }
                }
            } 
            catch (error) 
            {
                console.log(error);
                setSpinner(false);
                if (error.error === 'Not Acceptable') 
                {
                    setComponentConditions((prevItems): IUserLoginComponentConditions => 
                    {
                        return {
                            ...prevItems,
                            invalidCredentials: false,
                            tooManyAttemptsErr: true,
                        }; 
                    });
                }
                else if (error?.error === 'Unauthorized')
                {
                    setComponentConditions((prevItems): IUserLoginComponentConditions => 
                    {
                        return {
                            ...prevItems,
                            invalidCredentials: true,
                        }; 
                    });
                }
                else if (error?.message === 'Weak Password') {
                    setErrorMsg('Please change your password by clicking on forgot password');
                }
            }
            finally
            {
                setSpinner(false);
            }
        }
    };

    const userSignup = async (values): Promise<void> =>
    {
        const signupObj = {
            email: values.email,
        };

        try 
        {
            const registered = await registerUserWithWorkOS(signupObj);
            if(registered)
            {
                setSingleCondition('isSignUpHelpTextComponent');
            }
        } 
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setSpinner(false);
        }
    };

    const handleLogin = async (values): Promise<void> => 
    {
        userLogin(values);
    };

    const handleSignup = async (values): Promise<void> =>
    {
        userSignup(values);
    };

    const getTokenInfo = async (): Promise<void> =>
    {
        try 
        {
            const userInfo = await getUserOrgInfoByToken(token || '');
            if(userInfo)
            {
                setUserEmail(userInfo?.email || '');
            }
        } 
        catch (error) 
        {
            console.log(error);
            toast.error(error?.message || 'Something went wrong, please try again.');
        }
    };

    const handleWorkOSLogin = async (): Promise<void> =>
    {
        const data = {
            email: userEmail,
            token: token || '',
        }

        try 
        {
            const authenticated = await googleAuthenticateWithWorkOS(data);
            if (authenticated) 
            {
                const user = await userMe();
                if (user) 
                {
                    // navigate('/events');
                    window.location.href = `${APP_CONSTANTS.APP_DOMAIN}/events`;
                    setSpinner(false);
                }
            }
        } 
        catch (error) 
        {
            console.log(error);
            if ((error as Error)?.message === 'login')
            {
                return setComponentConditions((prevItems): IUserLoginComponentConditions => 
                {
                    return {
                        ...prevItems,
                        invalidCredentials: true,
                    }; 
                });
            }
            toast.error(error?.message || 'Something went wrong, please try again.');
            setSpinner(false);
        }
    };

    const handleTogglePassword = async (): Promise<void> => 
    {
        setComponentConditions((prevItems): IUserLoginComponentConditions => 
        {
            return {
                ...prevItems,
                showPassword: !componentConditions.showPassword
            }; 
        });
    };

    const handleResetPassword = async (values): Promise<void> => 
    {
        const email = values.email;
        setUserEmail(email);
        try 
        {
            const resetEmailSent = await resetPasswordWithWorkOS({
                email 
            });
            if (resetEmailSent) 
            {
                setSpinner(false);
                setComponentConditions((prevItems): IUserLoginComponentConditions => 
                {
                    return {
                        ...prevItems,
                        isResetPasswordComponent: false,
                        isResetHelpTextComponent: true,
                    }; 
                });
            }
        }
        catch (error) 
        {
            setSpinner(false);
            setComponentConditions((prevItems): IUserLoginComponentConditions => 
            {
                return {
                    ...prevItems,
                    isResetPasswordComponent: false,
                    isResetHelpTextComponent: false,
                    tooManyResetAttempts: true,
                }; 
            });
            console.log(error);
        }
    };

    const handleRedirectLogin = (): void => 
    {
        setSingleCondition('isLoginComponent');
        if(componentConditions?.isSignUpComponent || componentConditions.isSignUpHelpTextComponent) navigate('/login');
    };

    const requestPasswordReset = async (): Promise<void> => 
    {
        setSingleCondition('isResetPasswordComponent');
    };

    const getIntegrationTypeAndRedirect = async (): Promise<void> =>
    {
        setWorkosSpinner(true);
        try 
        {
            const data = await integrateWorkOS(WorkosLoginType.GOOGLE_OAUTH);
            
            if (data) 
            {
                window.location.href = data;
            }
        } 
        catch (error) 
        {
            toast.error(error?.message || 'Something went wrong, please try again.');
        }
        finally
        {
            setWorkosSpinner(false);
        }
    }

    const getTitleContent = () => {
        if (componentConditions.isLoginComponent || componentConditions.allLoginOptions) return 'Welcome to Photo Sharing';
        if (componentConditions.isResetPasswordComponent) return 'Forgot Password?';
        if ((componentConditions.isResetHelpTextComponent) || (componentConditions.isSignUpHelpTextComponent)) return 'Check your email';
        if (componentConditions.tooManyResetAttempts) return 'Exceeded maximum attempts';
        if (componentConditions.isSignUpComponent) return 'Create your account';
        return '';
    };
    
    const getSubTitleContent = () => {
        if(componentConditions.isLoginComponent) return 'Enter your Email Id and Password'
        if (componentConditions.isResetPasswordComponent) return 'No worries, we’ll send you reset instructions.';
        if (componentConditions.isResetHelpTextComponent) return <>We sent a password reset link to <strong>{userEmail}</strong></>;
        if (componentConditions.tooManyResetAttempts) return 'You have exceeded maximum attempts, please try again after 5 minutes.';
        if (componentConditions.isSignUpHelpTextComponent) return `We have sent a link to ${userEmail}. Click on the link to access your account.`;
        return '';
    };

    const validationSchema = Yup.object().shape(
        (componentConditions.allLoginOptions || componentConditions.isResetPasswordComponent || componentConditions.isSignUpComponent) ? {
            email: Yup.string()
                .required('Email is required')
                .email('Enter valid Email'),
        } :
        componentConditions.isLoginComponent ? {
            email: Yup.string()
                .required('Email is required')
                .email('Enter valid Email'),
            password: Yup.string()
                .required('Please enter your password'),
        } : {}
    );

    const { Formik } = formik;

    return (
        <Box id="userLoginPage">
            <img src={eventHqLogo} alt="eventHqLogo" className="header-logo-img" />
            {import.meta.env.VITE_NODE_ENV === 'demo' ?
                <Box className="demo-login-cont">
                    <Box className="demo-auth-container">
                        <Box className="demo-header-container">
                            <Box component="img" src={eventhqFavicon} className="logo-image" />
                        </Box>
                        <Box className="demo-info-cont">
                            <Typography className="info-text">{'Setting up demo instance'}</Typography>
                        </Box>
                    </Box>
                    <Spinner size='sm' className="demo-auth-spinner" />
                </Box> :
                <Formik
                    key={String(componentConditions.isLoginComponent)}
                    validationSchema={validationSchema}
                    enableReinitialize={true}
                    validateOnChange={false}
                    // validateOnMount={true}
                    initialTouched={
                        (componentConditions.allLoginOptions || componentConditions.isResetHelpTextComponent || componentConditions.isResetPasswordComponent || componentConditions.isSignUpComponent) ?
                        {
                            email: true,
                        }
                        :
                        componentConditions.isLoginComponent ?
                        {
                            email: false,
                            password: false
                        }
                        : {}
                    
                    }
                    initialValues={
                        (componentConditions.allLoginOptions || componentConditions.isResetHelpTextComponent || componentConditions.isResetPasswordComponent || componentConditions.isSignUpComponent) ?
                        {
                            email: userEmail || '',
                        }
                        :
                        componentConditions.isLoginComponent ?
                        {
                            email: userEmail || '',
                            password: ''
                        }
                        :
                        {}
                    }
                    onSubmit={(values): Promise<void> => 
                    {
                        setSpinner(true);
                        if (componentConditions.isLoginComponent) 
                        {
                            return handleLogin(values);
                        }
                        else if (componentConditions.isResetPasswordComponent)
                        {
                            return handleResetPassword(values);
                        }
                        else if (componentConditions.isSignUpComponent)
                        {
                            return handleSignup(values);
                        }
                    }}
                >
                    {({ handleSubmit, handleChange, values, errors, validateField, setFieldValue, touched, setFieldTouched, validateForm, isValid, validateOnChange }): React.ReactElement => 
                    {
                        return (
                            <Form noValidate onSubmit={handleSubmit}>
                                <Box className="auth-page-container">
                                    <Box className="title-container">
                                        <Typography className="title-content">{getTitleContent()}</Typography>
                                        {getSubTitleContent() && <Typography className="sub-title-content">{getSubTitleContent()}</Typography>}
                                        {componentConditions.isResetHelpTextComponent && <Typography className="reset-help-text">{'If we find a match, you’ll get an email with further instructions. If you don’t hear from us in the next 15 minutes,please double check that you entered the correct email address and check your spam folder.'}</Typography>}
                                    </Box>
                                    <Box className="login-form-container">
                                        {(componentConditions.isLoginComponent || componentConditions?.allLoginOptions || componentConditions.isResetPasswordComponent || componentConditions.isSignUpComponent) && <Form.Group className="email-form-group">
                                            <FormLabelComponent label='Email' />
                                            <FormControlComponent
                                                value={values.email || ''}
                                                onChange={(event) => { setFieldValue('email' , event.target.value); setUserEmail(event.target.value); }}
                                                name={'email'}
                                                type='email'
                                                required
                                                placeholder='johndoe@yourcompany.com'
                                                isInvalid={!!errors.email}
                                            />
                                        </Form.Group>}
                                        {
                                            componentConditions?.allLoginOptions &&
                                            <div>
                                                <div className="sidebar-container-spacing">
                                                    <CustomButton 
                                                        name='Next'
                                                        type="button"
                                                        btnType="primary"
                                                        style={{ width: '100%', height: '44px', maxHeight: '44px', fontSize: '16px' }}
                                                        onClick={async (): Promise<void> => 
                                                        {
                                                            await validateField('email');
                                                            const validationErrors = await validateForm();
                                                            if (touched.email && !validationErrors.email) 
                                                            {
                                                                setSingleCondition('isLoginComponent');
                                                            } 
                                                            else 
                                                            {
                                                                setFieldTouched('email', true);
                                                            }
                                                        }}
                                                    />
                                                </div>

                                                <div className="divider-container">
                                                    <p className="midline"></p>
                                                    <p className="or-text">{'OR'}</p>
                                                </div>

                                                <div>
                                                    <CustomButton 
                                                        name='Continue with Google'
                                                        type="button"
                                                        btnType='secondary'
                                                        loading={workosSpinner}
                                                        style={{ width: '100%', height: '44px', maxHeight: '44px', fontSize: '16px' }}
                                                        startIcon={<Box component="img" src={googleLogo} />}
                                                        onClick={async (): Promise<void> => 
                                                        {
                                                            // await validateField('email');
                                                            // const validationErrors = await validateForm();
                                                            // if (touched.email && !validationErrors.email) 
                                                            // {
                                                                // setSingleCondition('isLoginComponent');
                                                            getIntegrationTypeAndRedirect();
                                                            // } 
                                                            // else 
                                                            // {
                                                            //     setFieldTouched('email', true);
                                                            // }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        }

                                        {
                                            componentConditions.isLoginComponent &&
                                            <>
                                                <Form.Group className="password-form-group sidebar-container-spacing">
                                                    <FormLabelComponent label='Password' />
                                                    <Box className="password-input-container">
                                                        <FormControlComponent
                                                            value={values.password}
                                                            onChange={handleChange}
                                                            name={'password'}
                                                            type={componentConditions.showPassword ? 'text' : 'password'}
                                                            required
                                                            isInvalid={!!errors.password}
                                                        />
                                                        <IconButton type="button" className="visibility-toggle-button" aria-label="show-password" onClick={handleTogglePassword}>
                                                            {!componentConditions.showPassword ? <FontAwesomeIcon icon={['fal', 'eye-slash']} className="show-hide-icon" /> : <FontAwesomeIcon icon={['fal', 'eye']} className="show-hide-icon" />}
                                                        </IconButton>
                                                    </Box>
                                                </Form.Group>
                                                <Box className="remember-forgot-password-container">
                                                    <Box className="remember-me-container">
                                                        <input type="checkbox" id="rememberMe" name="rememberMe" value="rememberMe" />
                                                        <label htmlFor="rememberMe">Remember for 30 days</label>
                                                    </Box>
                                                    <Button type="button" className="forgot-password-button" onClick={requestPasswordReset}>Forgot Password?</Button>
                                                </Box>
                                            </>
                                        }
                                    </Box>
                                    {(componentConditions.invalidCredentials || errorMsg!== '') ?
                                        <Box className="invalid-content-container">
                                            <Typography className="invalid-main-content">{errorMsg === '' ? 'Invalid Credentials' : 'Weak Password'}</Typography>
                                            <Typography className="invalid-sub-content">{errorMsg === '' ? 'The email and password you entered do not match.' : errorMsg}</Typography>
                                        </Box> :
                                        componentConditions.tooManyAttemptsErr ?
                                            <Box className="invalid-content-container">
                                                <Typography className="invalid-main-content">{'Exceeded maximum attempts. Please try again after 5 minutes.'}</Typography>
                                            </Box> : null
                                    }
                                    {(componentConditions.isLoginComponent || componentConditions.isResetPasswordComponent || componentConditions.isSignUpComponent) && <CustomButton style={{ width: '372px', height: '44px', maxHeight: '44px', fontSize: '16px', margin: componentConditions.isResetPasswordComponent ? '24px 0' : undefined }} disabled={spinner} type="submit" btnType="primary" loading={spinner} name={componentConditions.isLoginComponent ? 'Sign in' : componentConditions.isResetPasswordComponent ? 'Reset Password' : 'Continue'} /> }
                                    {
                                        (componentConditions.isResetPasswordComponent || componentConditions.isResetHelpTextComponent || componentConditions.tooManyResetAttempts || componentConditions?.isSignUpComponent || componentConditions.isSignUpHelpTextComponent) &&
                                        <Box className="login-redirect-container">
                                            <CustomButton style={{ color: 'var(--Component-colors-Components-Buttons-Tertiary-button-tertiary-fg, #475467)', fontWeight: 600 }} startIcon={<FontAwesomeIcon icon={['fal', 'arrow-left']} />} btnType='infoBlue' name='Back to Log in' type="button" onClick={handleRedirectLogin} />
                                        </Box>
                                    }
                                    {(componentConditions.allLoginOptions || componentConditions.isLoginComponent) &&
                                        <div className="signupinfo-and-privacy-container">
                                            <p className="signup-info-btn">{`Don't have an account`} <a href={`${APP_CONSTANTS.APP_DOMAIN}/signup`}>Sign up</a></p>
                                            <Box className="privacy-container">
                                                <Typography className="sub-title-content">{`By clicking 'Sign in', I accept the EventHQ `}<a href='https://www.eventhq.com/terms-of-service' target="_blank">{'Terms of Service'}</a>{' and '}<a href='https://www.eventhq.com/privacy-policy' target="_blank">{'Privacy Policy'}</a></Typography>
                                            </Box>
                                        </div> 
                                    }
                                </Box>
                            </Form>
                        );
                    }}
                </Formik>}
        </Box >
    );
};

export default UserLoginPage;