import { FilePond, registerPlugin } from "react-filepond";

// Import FilePond styles
import "filepond/dist/filepond.min.css";

// Import the Image EXIF Orientation and Image Preview plugins
// Note: These need to be installed separately
// `npm i filepond-plugin-image-preview filepond-plugin-image-exif-orientation --save`
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import { useEffect, useState } from "react";
import APP_CONSTANTS from "../../../../scripts/constants";

import uploadIcon from '../../../../assets/icons/upload-icon.svg';
import { enableDetectFaces } from "../../../../scripts/apis/eventPhotoSharing";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview, FilePondPluginFileValidateSize, FilePondPluginFileValidateType);

const FilePondPhotos = ({ eventId, folderId, isDefault, csrfToken}: { eventId: string | number, folderId: string | number, isDefault: boolean | undefined, csrfToken: string }) => 
{
  const [files, setFiles] = useState([]);

  const [uploadProgress, setUploadProgress] = useState<number>(0);
  const [completedUploads, setCompletedUploads] = useState<number>(0);
  const [totalFiles, setTotalFiles] = useState<number>(0);
  const [successfulUploads, setSuccessfulUploads] = useState<any>([]);
  const [failedUploads, setFailedUploads] = useState<any>([]);
  const [uploadedPhotoIds, setUploadedPhotoIds] = useState<string[]>([]);

  useEffect(() => {
    if (files.length > 0) {
      setTotalFiles(files.length);
      setUploadProgress((completedUploads / files.length) * 100);
    }
  }, [files]);

  const handleDetectFaces = async (): Promise<void> =>
  {

    if(uploadedPhotoIds.length === 0) return;
    
    const photoIdsData = {
      photoIds: uploadedPhotoIds,
    };

    try 
    {
      const success = await enableDetectFaces(eventId, folderId, photoIdsData, csrfToken);
      if (success) 
      {
        const successfulUploadNames = successfulUploads?.map(file => file?.name); 

        const filteredFiles = files.filter(file => {
          return !successfulUploadNames.includes(file?.file?.name) 
        }); 
        setFiles(filteredFiles);
        setSuccessfulUploads([]);
        setCompletedUploads(0);
        setTotalFiles(0);
        setUploadProgress(0);
        setUploadedPhotoIds([]);
      } 
    } 
    catch (error) 
    {
      console.log('Error in enabling face detection', error);
    }
  }; 

  useEffect(() => {
    if(successfulUploads?.length > 0 && totalFiles > 0)
    {
      if(successfulUploads.length + failedUploads.length === totalFiles) 
      {
        handleDetectFaces();
      }
    }
  }, [successfulUploads, failedUploads, totalFiles, uploadedPhotoIds]);
  
  const handleProcess = (fieldName, file, metadata, load, error, progress, abort) => 
  {
    const formData = new FormData();
    formData.append('files', file);
  
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1}/event-photo-sharing/${eventId}/${folderId}/upload-images${isDefault ? '?type=1' : ''}`);
  
    xhr.withCredentials = true;
    xhr.setRequestHeader('X-CSRF-TOKEN', csrfToken);
    xhr.upload.onprogress = (e) => {
      if (e.lengthComputable) {
        const percentage = (e.loaded / e.total) * 100;
        // setUploadProgress(percentage); // Update upload progress
      }
    };
  
    xhr.onload = () => {
      if (xhr.status === 201) {
        const response = JSON.parse(xhr.responseText);
        const photoId = response[0];
  
        setUploadedPhotoIds((prevIds) => [...prevIds, photoId]);
  
        setSuccessfulUploads((prev) => [...prev, file]);
        setCompletedUploads((prev) => {
          const newCompletedUploads = prev + 1;
          setUploadProgress((newCompletedUploads / totalFiles) * 100); // Update progress dynamically
          setFailedUploads((prev) => prev?.filter(failedFile => failedFile !== file)); // Remove file from failedUploads
  
          return newCompletedUploads;
        });
        load(xhr.responseText);
      } else {
        setFailedUploads((prev) => [...prev, file]);
        error('Upload failed');
      }
    };
  
    xhr.onerror = () => {
      setFailedUploads((prev) => [...prev, file]);
      error('Upload failed');
    };
  
    xhr.ontimeout = () => {
      setFailedUploads((prev) => [...prev, file]);
      error('Upload timed out');
    };
  
    xhr.send(formData);
  
    return {
      abort: () => {
        xhr.abort();
        abort();
      },
    };
  };

  return (
    <div className="photo-sharing-files-upload">
      {files.length > 0 ? <div>
        <p>{completedUploads}/{totalFiles} uploaded</p>
        <progress className="photos-uploaded-progress" value={uploadProgress} max="100" />
      </div> : <div></div>}
      <FilePond
        files={files}
        allowReorder={true}
        allowMultiple={true}
        name="files"
        maxFileSize={'25MB'}
        maxFiles={200}
        allowFileTypeValidation={true}
        acceptedFileTypes={['image/png', 'image/jpeg']}
        labelFileTypeNotAllowed="Invalid file type"
        allowFileSizeValidation={true}
        labelMaxFileSizeExceeded="File is too large"
        labelMaxFileSize="Maximum file size is 25MB"
        server={{ process: handleProcess }}
        onprocessfile={(error, file) => 
        {
          if (!error) {
            setFiles((prevFiles) => prevFiles.filter((prevFile) => prevFile !== file));
          }
        }}
        // server={{
        //     url: `${APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1}`,
        //     process: {
        //         url: `/event-photo-sharing/${eventId}/${folderId}/upload-images${isDefault ? '?type=1' : ''}`,
        //         method: "POST",
        //         withCredentials: true,
        //         headers: {
        //             "X-CSRF-TOKEN": csrfToken,
        //         }
        //     }
        // }}
        onupdatefiles={setFiles}
        maxParallelUploads={20}
        labelIdle={`<div><div class="upload-div"> 
                      <div class="upload-icon-div">
                        <img src=${uploadIcon} />
                      </div>
                  <div class="upload-text-div"><span class="upload-btn">Click to upload</span><span class="upload-drag-content"> or drag and drop</span></div>
                </div></div>`}
      />
    </div>
  );
}

export default FilePondPhotos;
