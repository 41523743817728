import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import FormTabs from "../FormTabs";
import TableFilterServerSide from "./TableFilterServerSide";
import ColumnVisibilityDropdown from "./ColumnVisibilityDropdown";

import './styles.scss';

interface ITanstackToolbar {
    columns: any[];
    setColumns?: React.SetStateAction<React.Dispatch<any[]>>;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
    customFilterFunctions?: { [key: string]: (obj: any, inputValue: string | number) => boolean };
    tabs?: { tabName: string, count: string | number, navigation: () => void, selectedTab: boolean }[];
    noFilter?: boolean;
    handleApplyFilters: (filters: { selectedColumn: string, inputValue: string }[]) => void;
    filterColumns: any[];
    extraActionButtons?: React.ReactNode[];
    minimalView?: boolean;
}

const TanstackToolbarWithServerFilter: React.FC<ITanstackToolbar> = ({ columns, setColumns, tabs, customFilterFunctions, handleApplyFilters, setCurrentUrl, filterColumns, extraActionButtons, minimalView }): React.JSX.Element => {

    const columnOptions = filterColumns
        .filter(column => column.id !== 'actions' && column.id !== 'copyLink')
        .map(column => {
            const columnOption: {
                label: any;
                value: any;
                type: any;
                filterOptions?: any;
            } = {
                label: typeof (column?.header) === 'string' ? column?.header : column?.meta?.headerName,
                value: column?.accessorKey || column?.id,
                type: column?.meta?.type,
            };

            if (column?.meta?.type === 'dropdown') {
                columnOption.filterOptions = column?.meta?.filterOptions;
            }

            return columnOption;
        }
        );

    const [filters, setFilters] = useState([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '', type: columnOptions[0]?.type || 'string' }]);

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].inputValue = value;
        setFilters(newFilters);
    };

    const handleDropdownChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const value = event.target.value;
        const newFilters = [...filters];
        newFilters[index].inputValue = value;
        setFilters(newFilters);
    };

    const handleDateChange = (date: any, index: number) => { 
        const newFilters = [...filters];
        newFilters[index].inputValue = moment(date).unix();
        setFilters(newFilters);
    };

    const handleColumnChange = (event: React.ChangeEvent<HTMLSelectElement>, index: number) => {
        const value = event.target.value;
        const newFilters = [...filters];
        const selectedOption = columnOptions.find(option => option.value === value);
        const selectedType = selectedOption?.type || 'string';
    
        newFilters[index].selectedColumn = value;
        newFilters[index].type = selectedType;
    
        if (selectedType === 'string') {
            newFilters[index].inputValue = '';
        } else if (selectedType === 'dropdown') {
            const defaultFilterOptions = selectedOption?.filterOptions;
            newFilters[index].inputValue = defaultFilterOptions?.[0]?.value || '';
        } else if (selectedType === 'date') {
            newFilters[index].inputValue = moment().unix();
        }
    
        setFilters(newFilters);
    };

    const addFilter = () => {
        const selectedColumns = filters.map(filter => filter.selectedColumn);
        const availableColumns = columnOptions.filter(option => !selectedColumns.includes(option.value));
        const defaultColumn = availableColumns.length > 0 ? availableColumns[0].value : '';
        const defaultType = columnOptions.find(option => option.value === defaultColumn)?.type || 'string';
        if (defaultType === 'string')
        {
            setFilters([...filters, { selectedColumn: defaultColumn, inputValue: '', type: defaultType }]);
            return;
        }
        if (defaultType === 'dropdown')
        {
            const defaultFilterOptions = columnOptions.find(option => option.value === defaultColumn)?.filterOptions;
            const defaultInputValue = defaultFilterOptions?.[0]?.value || '';
            setFilters([...filters, { selectedColumn: defaultColumn, inputValue: defaultInputValue, type: defaultType }]);
            return;
        }
        if (defaultType === 'date')
        {
            setFilters([...filters, { selectedColumn: defaultColumn, inputValue: moment().unix(), type: defaultType }]);
        }
        // const defaultInputValue = defaultType === 'dropdown' ? columnOptions.find(option => option.value === defaultColumn)?.filterOptions?.[0]?.value || '' : '';
        // setFilters([...filters, { selectedColumn: defaultColumn, inputValue: defaultInputValue, type: defaultType }]);
    };

    const removeFilter = (index: number) => {
        const newFilters = filters.filter((_, i) => i !== index);
        setFilters(newFilters);
    };

    const clearFilters = () => {
        setFilters([{ selectedColumn: columnOptions[0]?.value || '', inputValue: '', type: columnOptions[0]?.type || 'string' }]);
        const currentUrlObj = new URL(window.location.href);
        currentUrlObj.search = '';
        window.history.replaceState({}, '', currentUrlObj);
        setCurrentUrl(currentUrlObj.href);
    };

    const searchParams = new URLSearchParams(useLocation().search);
    const getFiltersFromQueryParams = () => {
        const filters: { selectedColumn: string, inputValue: string, type: string }[] = [];
    
        // Use URLSearchParams to get the search params
        searchParams.forEach((value, key) => {
            const columnOption = columnOptions.find(option => option.value === key);
            filters.push({ 
                selectedColumn: key, 
                inputValue: decodeURIComponent(value),
                type: columnOption?.type || 'string' 
            });
        });
    
        // Set the filters in the state
        if (filters?.length > 0) {
            setFilters(filters);
        } else {
            setFilters([{ 
                selectedColumn: columnOptions[0]?.value || '', 
                inputValue: '', 
                type: columnOptions[0]?.type || 'string' 
            }]);
        }
    };
    
    useEffect(() => {
        getFiltersFromQueryParams();
    }, [useLocation().search]);

    return (
        <div id="tanstackFilterWithServerFilter">
            {
                minimalView ? 
                <div>
                    <TableFilterServerSide
                        columnOptions={columnOptions}
                        filters={filters}
                        handleColumnChange={handleColumnChange}
                        handleInputChange={handleInputChange}
                        handleDropdownChange={handleDropdownChange}
                        handleDateChange={handleDateChange}
                        handleApplyFilters={() => handleApplyFilters(filters)}
                        addFilter={addFilter}
                        removeFilter={removeFilter}
                        clearFilters={clearFilters}
                        customFilterFunctions={customFilterFunctions || undefined}
                        minimalView
                    />
                </div> :
                <div className="tanstack-toolbar-server-filter">
                    <FormTabs
                        tabs={tabs}
                    />
                    <div className="toolbar-actions">
                        {extraActionButtons && extraActionButtons?.map((button, index) => {
                            return (
                                <div key={index} className="extra-action-button">
                                    {button}
                                </div>
                            )
                        })}
                        <TableFilterServerSide
                            columnOptions={columnOptions}
                            filters={filters}
                            handleColumnChange={handleColumnChange}
                            handleInputChange={handleInputChange}
                            handleDropdownChange={handleDropdownChange}
                            handleDateChange={handleDateChange}
                            handleApplyFilters={() => handleApplyFilters(filters)}
                            addFilter={addFilter}
                            removeFilter={removeFilter}
                            clearFilters={clearFilters}
                            customFilterFunctions={customFilterFunctions || undefined}
                        />
                        <ColumnVisibilityDropdown
                            columns={columns}
                            setColumns={setColumns}
                        />
                    </div>
                </div>
            }
        </div>
    )
};

export default TanstackToolbarWithServerFilter;