import { EventPhotoSharingFolderType, EventPhotoSharingStatus, EventPhotoSharingTypeFilter } from '../../pages/Events/enum/event-photo-sharing.enum';
import { EventPhotoSharing, EventPhotoSharingFolder, EventPhotosUploaded } from '../../pages/Events/interfaces/event-photo-sharing_interface';
import APP_CONSTANTS from '../constants';
import { convertJSONToGETParams, triggerApi } from '../helpers';

// GET API's

export const getPhotosCount = async (eventId: string | number, folderId: string | number): Promise<number> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/images/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getPhotosForFolder = async (eventId: string | number, folderId: string | number, pageSize: number, offset: number, status?: EventPhotoSharingStatus): Promise<EventPhotoSharing[]> =>
{
    const query = convertJSONToGETParams({ 
        pageSize,
        offset,
        status
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/images?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getAllDefaultAndHiddenPhotos = async (eventId: number | string, pageSize: number, offset: number, filterType: EventPhotoSharingTypeFilter): Promise<EventPhotoSharing[]> =>
{
    const query = convertJSONToGETParams({ 
        pageSize,
        offset,
        filterType
    });
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/images?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getFolderCount = async (eventId: string | number): Promise<number> => 
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/folders/count';
    return triggerApi(API_URL, 'GET', '');
};

export const getAllFolders = async (pageSize: number, offset: number, eventId: string | number, defaultFolderType?: EventPhotoSharingFolderType): Promise<EventPhotoSharingFolder[]> =>
{

    const query = convertJSONToGETParams({
        pageSize,
        offset,
        defaultFolderType
    });

    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/folders?' + query;
    return triggerApi(API_URL, 'GET', '');
};

export const getImageByImageId = async (eventId: string | number, imageId: string | number): Promise<string> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/photos/' + eventId + '/' + imageId;
    return triggerApi(API_URL, 'GET', '');
};

// POST API's

export const createFolder = async (eventId: string | number, folderData: any, csrfTokenValue: string): Promise<EventPhotoSharingFolder> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/folder';
    return triggerApi(API_URL, 'POST', folderData, csrfTokenValue);
};

export const uploadPhotosToFolder = async (eventId: string | number, folderId: string | number, photos: any, csrfTokenValue: string): Promise<string[]> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/upload-images';
    return triggerApi(API_URL, 'POST', photos, csrfTokenValue, false);
};

export const enableDetectFaces = async (eventId: string | number, folderId: string | number, photos: EventPhotosUploaded, csrfTokenData: string): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/detect-faces';
    return triggerApi(API_URL, 'POST', photos, csrfTokenData);
};

// PUT API's

export const updateFolder = async (eventId: string | number, folderId: string | number, folderData: any): Promise<EventPhotoSharingFolder> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/folder';
    return triggerApi(API_URL, 'PUT', folderData);
};

export const hidePhotos = async (eventId: string | number, folderId: string | number, data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/hide';
    return triggerApi(API_URL, 'PUT', data);
};

export const markDefaultPhotos = async (eventId: string | number, folderId: string | number, data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/mark-default';
    return triggerApi(API_URL, 'PUT', data);
};

export const approveUserUploadedPhotos = async (eventId: string | number, data: { photoIds: string[] | number[] }): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/approve';
    return triggerApi(API_URL, 'PUT', data);
};

// DELETE API's

export const deletePhotos = async (eventId: string | number, folderId: string | number, data: any): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId;
    return triggerApi(API_URL, 'DELETE', data);
};

export const deleteFolder = async (eventId: string | number, folderId: string | number): Promise<boolean> =>
{
    const API_URL = APP_CONSTANTS.API_DOMAIN + APP_CONSTANTS.API_V1 + '/event-photo-sharing/' + eventId + '/' + folderId + '/folder';
    return triggerApi(API_URL, 'DELETE', '');
};