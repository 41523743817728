import { createContext, useEffect, useState } from 'react';
import React from 'react';
import { deleteEvent, getEvents } from '../scripts/apis/events';
import eventBus from '../scripts/event-bus';
import { useDispatch } from 'react-redux';
import { addEventData, refreshEventsData } from '../redux/events/EventsData';
import { useTablePagination } from './TablePaginationContext';
import _ from 'lodash';
import { Event } from '../pages/Events/interfaces';
import { useParams } from 'react-router-dom';
import toast from 'react-hot-toast';

interface IEventsPageContext {
    isEmpty: boolean;
    setIsEmpty: (isEmpty: boolean) => void;
    setEventDataCount: (count: number) => void;
    setRows: (rows: any) => void;
    setEvents: (events: any) => void;
    rows: any;
    eventDataCount: number;
    events: any;
    statusCounts: undefined;
    deleteEventFromEventsTable: (eventId: string | number) => void;
    setRefresh: React.Dispatch<React.SetStateAction<boolean>>;
    pageSize: number;
    updateCurrentPage: (page: number) => void;
    updatePageSize: (size: number) => void;
    showSpinner: boolean;
    eventsTypeData: any;
    eventStatusData: any;
    eventLocationData: any;
    eventsTrendData: any;
    currentPage: number;
    showDeletePopup: boolean;
    setShowDeletePopup: React.Dispatch<React.SetStateAction<boolean>>;
    currentUrl: string;
    setCurrentUrl: React.Dispatch<React.SetStateAction<string>>;
}

export const EventsPageContext = createContext<IEventsPageContext>({
    isEmpty: false,
    setIsEmpty: () => {},
    setEventDataCount: () => {},
    setRows: () => {},
    setEvents: () => {},
    rows: [],
    eventDataCount: 0,
    events: [],
    deleteEventFromEventsTable: () => {},
    setRefresh: () => {},
    pageSize: 0,
    updateCurrentPage: () => {},
    updatePageSize: () => {},
    showSpinner: false,
    eventsTypeData: [],
    statusCounts: undefined,
    eventStatusData: [],
    eventLocationData: [],
    eventsTrendData: {},
    currentPage: 0,
    showDeletePopup: false,
    setShowDeletePopup: () => {},
    currentUrl: '',
    setCurrentUrl: () => {},
});

const EventsPageProvider: React.FC<{children: React.ReactNode}> = ({ children }): React.JSX.Element =>
{

    const { status } = useParams();

    const minimalView = true;

    const [isEmpty, setIsEmpty] = useState<boolean>(false);
    const [events, setEvents] = useState<Event[]>([]);
    const [eventDataCount, setEventDataCount] = useState<number>(0);
    const [rows, setRows] = useState<Event[]>([]);
    const [refresh, setRefresh] = useState<boolean>(false);
    const dispatch = useDispatch();
    const [showSpinner, setShowSpinner] = useState<boolean>(true);
    const [statusCounts, setEventStatusCount] = useState();
    const [eventStatusData, setEventsStatusData] = useState([]);
    const [showDeletePopup, setShowDeletePopup] = useState<boolean>(false);
    const [currentUrl, setCurrentUrl] = useState(window.location.href);

    const { pageSize, currentPage, updateCurrentPage, updatePageSize } = useTablePagination();

    const fetchData = async (): Promise<void> => 
    {
        setShowSpinner(true);
        try 
        {
            const params: [string, any][] = [];
            const query = new URLSearchParams(currentUrl.split('?')[1]);
            query?.forEach((value, key) => {
                params.push([key, value]);
            });

            const encodedParams = params.map(([key, value]) => [key, encodeURIComponent(value)]);
            if (minimalView)
            {
                const eventsData = await getEvents(eventDataCount, 0, ...encodedParams);
                if (eventsData) 
                { 
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setIsEmpty(eventsData.length === 0);
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }
            else
            {
                const eventsData = await getEvents(pageSize, currentPage - 1, ...encodedParams);
                if (eventsData) 
                {
                    setEvents([...eventsData]);
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setIsEmpty(eventsData.length === 0);
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    setRefresh(false);
                }
            }

        }
        catch (error) 
        {
            console.log(error);
        }
        finally
        {
            setShowSpinner(false);
        }
    };

    const fetchPaginationData = async (): Promise<void> => 
    {
        if (eventDataCount) 
        {
            try 
            {
                const eventsData = await getEvents(pageSize, currentPage - 1);
                setShowSpinner(true);
                if (eventsData) 
                {
                    setEvents([...eventsData]);
                    dispatch(refreshEventsData([]));
                    dispatch(addEventData(eventsData));
                    setIsEmpty(eventsData.length === 0);
                    setRows([...eventsData]);
                    setShowSpinner(false);
                    eventBus.dispatch('table-selected-tab', 0);
                    setRefresh(false);
                }

            }
            catch (error) 
            {
                console.log(error);
                setShowSpinner(true);
            }
        }
    };

    useEffect((): void => 
    {
        if ((currentPage && pageSize > 0) || minimalView) 
        {
            fetchData();
        }
    }, [eventDataCount, currentPage, pageSize, currentUrl]);

    useEffect((): void => 
    {
        if (refresh) 
        {
            fetchData();
        }
    }, [refresh, currentUrl]);

    const deleteEventFromEventsTable = async (eventId: string | number): Promise<void> => 
    {
        try 
            {
                const eventDeleted = await deleteEvent(eventId);
                if (eventDeleted) 
                {
                    setShowDeletePopup(false);
                    setRefresh(true);
                    toast.success('Event deleted successfully');
                }
            }
            catch (error) 
            {
                console.log(error);
            }
    };

    return (
        <EventsPageContext.Provider 
            value={{
                isEmpty, 
                setIsEmpty, 
                setEventDataCount, 
                setRows, 
                setEvents, 
                rows, 
                eventDataCount, 
                events, 
                deleteEventFromEventsTable, 
                setRefresh, 
                pageSize, 
                updateCurrentPage, 
                statusCounts,
                updatePageSize, 
                showSpinner, 
                eventStatusData, 
                currentPage, 
                showDeletePopup,
                setShowDeletePopup,
                currentUrl,
                setCurrentUrl,
            }}
        >
            {children}
        </EventsPageContext.Provider>
    );
};

export default EventsPageProvider;